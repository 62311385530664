<script setup>
import { useRouter } from "vue-router";

const props = defineProps({
  label: {
    type: String,
  },
  path: {
    type: String,
  },
  link: {
    type: String,
  },
});

const router = useRouter();

const pushTo = () => {
  if (props.path) {
    router.push({ path: props.path });
  }
  if (props.link) {
    window.open(props.link);
  }
};
</script>

<template>
  <div class="flowButton" @click="pushTo" data-anim="flowButton">
    <div
      class="flowButton__container"
    >
      <span class="spanAnim">{{ $t(label) }}</span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.flowButton {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  width: 100%;
  height: 80px;
  color: $white;
  overflow: hidden;
  position: relative;
  transition: all linear 0.5s;
  -webkit-transition: all linear 0.5s;
  cursor: pointer;

  &__container {
    border: 2px solid $white;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: inherit;
    height: inherit;
    display: flex;
  }

  ::after {
    display: block;
    pointer-events: none;
    content: "";
    position: absolute;
    background: $black;
    width: 180%;
    height: 100%;
    clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
    animation: flow 3s forwards;
  }

  @keyframes flow {
    100% {
      transform: translate(100%);
    }
  }

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.spanAnim {
  animation: appear 3s;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  37% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.flowButton:hover {
  background: $white;
  color: $black;
}

@media (max-width: 1023px) {
  .flowButton {
    ::after {
      display: none;
    }
  }
}
</style>
